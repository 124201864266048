var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-theme-provider",
    { attrs: { light: "" } },
    [
      _c(
        "cs-flex",
        { staticClass: "landing", attrs: { vertical: "", fillHeight: "" } },
        [_c("cs-flex", [_c("login")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }