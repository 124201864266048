
import { openAuthWindow, popUpToMicrosoftAuth } from '@/modules/auth'
import { CsBtn, CsCard, CsFlex } from '@consteel/csuetify'
import OnStockLogoSvg from '@/assets/OnStockLogo.svg.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'Login',
  components: {
    CsBtn,
    CsCard,
    OnStockLogoSvg,
    CsFlex,
  },
  methods: {
    openAuthWindow: openAuthWindow,
    openMicrosoftAuthWindow: popUpToMicrosoftAuth,
  },
})
