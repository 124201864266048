
import Login from '@/components/Landing/Login.vue'
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'Landing',
  components: {
    Login,
    CsFlex,
  },
})
